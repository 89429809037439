import HttpServiceCommonResult from 'shared/networking/http_service_common_result';
import IChecktypeHistoryOverview from '../models/checktype_history/checktype_history_overview';
import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from './config.service';

class ChecktypeHistoryServiceAPI extends HttpServiceCommonResult {
    public async getChecktypesHistoryOverview(): Promise<ApiResult<Array<IChecktypeHistoryOverview>>> {
        return this.get<Array<IChecktypeHistoryOverview>>(`/${appConfig.proxy}/plausibilitychecktypehistory`);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const ChecktypeHistoryService = new ChecktypeHistoryServiceAPI(TimeoutMilliseconds);

export default ChecktypeHistoryService;
