import { getConfig } from "./config.service";
import HttpServiceCommonResult from "shared/networking/http_service_common_result";
import { ApiResult } from "shared/networking/api_result";
import { IAuthData } from "models/authentication/authentication";

export class AuthServiceAPI extends HttpServiceCommonResult {

    public async fetchAuthData(): Promise<ApiResult<IAuthData>> {
        return this.getAnonym<IAuthData>(`/account/user`);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const AuthService = new AuthServiceAPI(TimeoutMilliseconds);

export default AuthService;