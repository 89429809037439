import { useAuth } from "shared/providers/auth_provider";
import { PrimaryButtonComponent } from "../../shared/shared";
import { Container } from "reactstrap";
import { NameClaim } from "models/authentication/authentication";

const UnauthorizedComponent = () => {

  const { authData, signOut } = useAuth();
  

  var showAccountInfo = false;
  var accountInfoBox = <></>;

  if (authData != null) {
    showAccountInfo = true;
    const humanName = authData.claims.find(cla => cla.type == NameClaim);
    accountInfoBox = (
      <div>
        <ul>
          <li>User: {humanName ? humanName.value : ''}</li>
          <li>UserName: {authData.name}</li>
        </ul>
      </div>
    );
  }

  return (
    <div>
      <Container>
        <h1>Kein Zugriff</h1>
        <p>
          Sie besitzen nicht die notwendigen Rechte, um auf diese Seite
          zuzugreifen
        </p>

        {showAccountInfo ? (
          <>
            <PrimaryButtonComponent onClick={() => signOut()}>
              Abmelden und mit anderem Konto anmelden
            </PrimaryButtonComponent>
            <h2>Account:</h2>
            {accountInfoBox}
          </>
        ) : (
            <></>
          )}
      </Container>
    </div>
  );
};

export default UnauthorizedComponent;
