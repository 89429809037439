export interface IAuthData {
    isAuthenticated: boolean;
    claims: IClaim[];
    nameClaimType: string;
    roleClaimType: string;
    name: string;
    roles: string[];
}

interface IClaim {
    type: string;
    value: string;
}

export const NameClaim = "name";