import React from "react";
import { HeaderComponent } from "./shared/components/page_layout/header.component";
import { MenuComponent } from "./shared/components/page_layout/menu.component";
import { ContentComponent } from "./shared/components/page_layout/content.component";
import { FooterComponent } from "./site/footer/footer.component";
import AppRouterComponent from "./shared/routing/approuter.component";
import { ErrorHandlerComponent } from "./shared/shared";
import { ThemeProvider } from "@mui/material";
import { materialTheme } from "./shared/style/theme";
import { SnackbarProvider } from 'notistack';
import "./medistradapro-webfont.woff2"
import "./SkolarSansPEWeb-Rg.woff2"
import "./SkolarSansPEWeb-Sb.woff2"
import "./App.css";
import { DialogProvider } from "shared/contexts/dialog_context";
import { BreadcrumbsRootComponent } from './shared/breadcrumbs/breadcrumbs_root.component';
import { Provider } from "react-redux";
import { reduxStore } from './app/redux_store';
import { getConfig } from "services/config.service";
import { BrowserRouter } from "react-router-dom";
import { LicenseInfo } from '@mui/x-license-pro';
import styled from "@emotion/styled";
import { initTranslation } from "services/translation.service";

const LayoutArea = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

var muiLicense = getConfig().muiLicense;
LicenseInfo.setLicenseKey(muiLicense);

const App = () => {
  const environmentName = getConfig().name;
  console.log(`Running MTM client app in environment: ${environmentName}`);
  initTranslation();

  return (
    <Provider store={reduxStore}>
      <ThemeProvider theme={materialTheme}>
        <BrowserRouter>
          <SnackbarProvider anchorOrigin={{ vertical: "bottom", horizontal: "right" }} >
            <DialogProvider>
                <LayoutArea className="layoutArea">
                <HeaderComponent />
                <MenuComponent />
                <BreadcrumbsRootComponent />
                <ContentComponent>
                    <ErrorHandlerComponent>
                    <AppRouterComponent />
                    </ErrorHandlerComponent>
                </ContentComponent>
                <FooterComponent />
                </LayoutArea>
            </DialogProvider>
          </SnackbarProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
