import { useImperativeHandle, useEffect, useRef, forwardRef } from "react";
import { ISimulationAdditionCategoryAttributeViewModel } from '../redux/simulator_simulation.model';
import { Additions, AdditionsEvaluatedEvent, FailedDataEvent, ComponentDataEvent, AdditionsLoadedEvent } from "@medi-mtm/components"
import { getConfigName } from "services/config.service";
import HttpServiceCommonResult from "shared/networking/http_service_common_result";

export type AdditionsWrapperComponentProps = {
    productConfigurationId: string;
    configurationRunId: number;
    countryId: string;
    qualityId?: string;
    attributeCategoryDefinitions?: ISimulationAdditionCategoryAttributeViewModel[];
    updateAdditionsEvaluating: () => {};
    updateAdditionsEvaluationFailed: (detail: FailedDataEvent) => void;
    onAdditionsComponentUpdated: (value: ComponentDataEvent) => void;
    updateAdditionsEvaluated: (event: AdditionsEvaluatedEvent) => void;
    updateAdditionsLoading: () => {};
    updateAdditionsLoadingFailed: (detail: FailedDataEvent) => void;
    updateAdditionsLoaded: (event: AdditionsLoadedEvent) => void;
}

export const AdditionsWrapperComponent = forwardRef<any, AdditionsWrapperComponentProps>((
    {
        productConfigurationId,
        configurationRunId,
        countryId,
        qualityId,
        attributeCategoryDefinitions,
        onAdditionsComponentUpdated,
        updateAdditionsEvaluating,
        updateAdditionsEvaluationFailed,
        updateAdditionsEvaluated,
        updateAdditionsLoading,
        updateAdditionsLoadingFailed,
        updateAdditionsLoaded,
    }, ref) => {
    const componentRef = useRef<Additions>(null);

    useImperativeHandle(ref, () => ({
        evaluateAdditions() {
            if (componentRef.current != null) {
                const additions = (componentRef.current);
                additions.evaluateAdditions();
            }
        },
        initializeAdditions() {
            initComponent();
        }
    }));

    const initComponent = async () => {
        const component = (componentRef.current);
        if (component) {
            component.init({
                Environment: getConfigName(),
                endpoints: {
                    getAdditions: "/mtmapi/productConfiguration/$1/additions",
                    evaluateAdditions: "/mtmapi/productConfiguration/additions",
                    downloadPositionDocument: "/mtmapi/document/positiondocument/$1/$2",
                    translations: "/mtmapi/localization/{{ns}}?language={{lng}}",
                }
            });

            component.setProductConfigurationInformation(productConfigurationId, configurationRunId);
            component.setCountryId(countryId);
            if (qualityId != null) {
                component.setQualityId(qualityId);
            }
            if (attributeCategoryDefinitions) {
                let attributeCategories = [];
                attributeCategoryDefinitions.filter(item => item.selectedAttribute.code != null).forEach(item => {
                    attributeCategories.push({
                        categoryErpId: item.erpId,
                        attributeId: item.selectedAttribute.code,
                    });
                });
                component.setAttributeCategories(attributeCategories);
            }
            component.load();
        }
    };

    useEffect(() => {
        const component = (componentRef.current);
        if (component) {
            component.setProductConfigurationInformation(productConfigurationId, configurationRunId);
        }
    }, [productConfigurationId, configurationRunId])

    useEffect(() => {
        const component = (componentRef.current);

        const additionsEvaluatingChangedHandler = (event: CustomEvent) => {
            updateAdditionsEvaluating();
        }

        const additionsEvaluationFailedChangedHandler = (event: CustomEvent<FailedDataEvent>) => {
            updateAdditionsEvaluationFailed(event.detail);
        }

        const getComponentStateHandler = (event: CustomEvent<ComponentDataEvent>) => {
            onAdditionsComponentUpdated(event.detail);
        }

        const additionsEvaluatedChangedHandler = (event: CustomEvent<AdditionsEvaluatedEvent>) => {
            updateAdditionsEvaluated(event.detail);
        }

        const additionsLoadingChangedHandler = (event: CustomEvent) => {
            updateAdditionsLoading();
        }

        const additionsLoadingFailedChangedHandler = (event: CustomEvent<FailedDataEvent>) => {
            updateAdditionsLoadingFailed(event.detail);
        }

        const additionsLoadedChangedHandler = (event: CustomEvent<AdditionsLoadedEvent>) => {
            updateAdditionsLoaded(event.detail);
        }

        if (component !== null) {
            component.addEventListener("additionsEvaluating", additionsEvaluatingChangedHandler);
            component.addEventListener("additionsEvaluationFailed", additionsEvaluationFailedChangedHandler);
            component.addEventListener("additionsEvaluated", additionsEvaluatedChangedHandler);
            component.addEventListener("additionsLoading", additionsLoadingChangedHandler);
            component.addEventListener("additionsLoadingFailed", additionsLoadingFailedChangedHandler);
            component.addEventListener("additionsLoaded", additionsLoadedChangedHandler);
            component.addEventListener("getComponentState", getComponentStateHandler);

        }

        return () => {
            if (component !== null) {
                component.removeEventListener("additionsEvaluating", additionsEvaluatingChangedHandler);
                component.removeEventListener("additionsEvaluationFailed", additionsEvaluationFailedChangedHandler);
                component.removeEventListener("additionsEvaluated", additionsEvaluatedChangedHandler);
                component.removeEventListener("additionsLoading", additionsLoadingChangedHandler);
                component.removeEventListener("additionsLoadingFailed", additionsLoadingFailedChangedHandler);
                component.removeEventListener("additionsLoaded", additionsLoadedChangedHandler);
                component.removeEventListener("getComponentState", getComponentStateHandler);
            }
        }
    }, [])

    return (<div>
        <x-additions
            ref={componentRef}
        ></x-additions>
    </div>);
})