import { PropsWithChildren, useContext, useMemo, createContext } from "react";
import { AuthServiceAPI } from "services/auth.service";

export interface ServiceContextContent {
    auth: AuthServiceAPI;
  }
  
  export const ServiceContext = createContext<ServiceContextContent>(
    {} as ServiceContextContent
  );
  

export const ServiceContextProvider = ({ children }: PropsWithChildren) => {
  const services: ServiceContextContent = useMemo(
    () => ({
      auth: new AuthServiceAPI(),
    }),
    []
  );
  return (
    <ServiceContext.Provider value={services}>
      {children}
    </ServiceContext.Provider>
  );
};

export const useServices = () => useContext(ServiceContext);