import {
    PropsWithChildren,
    ReactElement,
    useContext,
    useEffect,
    useState,
    createContext
} from "react";
import { useServices } from "./service_provider";
import { IAuthData } from "models/authentication/authentication";

export interface AuthContextContent {
    authData?: IAuthData;
    signIn: () => void;
    signOut: () => void;
}

export const AuthContext = createContext<AuthContextContent>({
    authData: undefined,
    signIn: () => undefined,
    signOut: () => undefined,
});
export const useAuth = () => useContext(AuthContext);

export const AuthContextProvider = ({
    children,
}: PropsWithChildren<unknown>): ReactElement => {
    const authContext = useContext(AuthContext);
    const [authData, setAuthData] = useState<IAuthData | undefined>(
        authContext.authData
    );

    const { auth } = useServices();

    useEffect(() => {
        (async() => {
            const response = await auth.fetchAuthData();
            if (response.isSuccessfull()) {
                const user: IAuthData = response.getData();

                user.name =
                    user.claims.find((c) => c.type === user.nameClaimType)?.value ||
                    "unknown";

                user.roles =
                    user.claims.filter((c) => c.type === user.roleClaimType).map(c => c.value) ||
                    [];

                if (!user.isAuthenticated) {
                    setAuthData({
                        isAuthenticated: false,
                        claims: [],
                        name: "",
                        nameClaimType: "",
                        roleClaimType: "",
                        roles: []
                    });
                    signIn();
                } else {
                    setAuthData(user);
                }
            }
        })();
    }, []);

    function signIn() {
        document.location.href = "/account/login";
    }

    function signOut() {
        document.location.href = "/account/logout";
    }

    return (
        <>
            {authData != null ? (
                <AuthContext.Provider value={{ authData, signIn, signOut }}>
                    {children}
                </AuthContext.Provider>
            ) : (
                <></>
            )}
        </>
    );
};
