import HttpServiceCommonResult from 'shared/networking/http_service_common_result';
import IMassmaskHistoryDetails from '../models/massmasks_history/massmask_history_details';
import IMassmaskHistoryOverview from "../models/massmasks_history/massmask_history_overview";
import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from './config.service';

class MassMaskHistoryServiceAPI extends HttpServiceCommonResult {
    public async getMassmasksHistoryOverview(): Promise<ApiResult<Array<IMassmaskHistoryOverview>>> {
        return this.get<Array<IMassmaskHistoryOverview>>(`/${appConfig.proxy}/massmaskhistory`);
    }

    public async getMassmasksHistoryDetails(historyId: number): Promise<ApiResult<IMassmaskHistoryDetails>> {
        return this.get<IMassmaskHistoryDetails>(`/${appConfig.proxy}/massmaskhistory/${historyId}`);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const MassMaskHistoryService = new MassMaskHistoryServiceAPI(TimeoutMilliseconds);

export default MassMaskHistoryService;
