import { IAdditionPosition } from "models/addition_position/addition_position";
import { IAdditionPositionCreate } from "models/addition_position/addition_position_create";
import { IAdditionPositionOverview } from "models/addition_position/addition_position_overview";
import { IAdditionPositionTranslation } from "models/addition_position/addition_position_translation";
import { IAdditionPositionUpdate } from "models/addition_position/addition_position_update";
import { ApiResult } from "../shared/networking/api_result";
import HttpServiceCommonResult from "../shared/networking/http_service_common_result";
import { getConfig } from './config.service';

class AdditionPositionServiceAPI extends HttpServiceCommonResult {
    public async getAdditionPositionTranslations(): Promise<ApiResult<IAdditionPositionTranslation[]>> {
        return this.get<IAdditionPositionTranslation[]>(`/${appConfig.proxy}/additionposition/translations`);
    }

    public async getAdditionPositionsOverview(): Promise<ApiResult<IAdditionPositionOverview[]>> {
        return this.get<IAdditionPositionOverview[]>(`/${appConfig.proxy}/additionposition`);
    }

    public async saveAdditionPosition(additionPosition: IAdditionPositionCreate): Promise<ApiResult<void>> {
        return this.post<IAdditionPositionCreate,void>(`/${appConfig.proxy}/additionposition`, additionPosition);
    }

    public async getAdditionPosition(id: string): Promise<ApiResult<IAdditionPosition>> {
        return this.get<IAdditionPosition>(`/${appConfig.proxy}/additionposition/${id}`);
    }

    public async updateAdditionPosition(additionPosition: IAdditionPositionUpdate): Promise<ApiResult<void>> {
        return this.put<IAdditionPositionUpdate,void>(`/${appConfig.proxy}/additionposition/`, additionPosition);
    }

    public async deleteAdditionPosition(additionPositionid: number): Promise<ApiResult<void>> {
        return this.delete(`/${appConfig.proxy}/additionposition/${additionPositionid}`);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const AdditionPositionService = new AdditionPositionServiceAPI(TimeoutMilliseconds);

export default AdditionPositionService;