import { ApiResult } from "shared/networking/api_result";
import { getConfig } from "./config.service";
import IBodyInformationOverview from "models/bodyinformation/bodyinformation_overview";
import IBodyInformationModelUpdate from "models/bodyinformation/bodyinformation_update";
import HttpServiceCommonResult from "shared/networking/http_service_common_result";
import { IBodyInformationCreate } from "models/bodyinformation/bodyinformation_create";
import { IBodyInformation } from "models/bodyinformation/bodyinformation";

class BodyInformationServiceAPI extends HttpServiceCommonResult {
    public async getBodyInformations(): Promise<ApiResult<Array<IBodyInformationOverview>>> {
        return this.get<Array<IBodyInformationOverview>>(`/${appConfig.proxy}/bodyinformation`);
    }

    public async createBodyInformation(bodyInformationCreate: IBodyInformationCreate): Promise<ApiResult<void>> {
        return this.post<IBodyInformationCreate,void>(`/${appConfig.proxy}/bodyinformation`, bodyInformationCreate);
    }

    public async getBodyInformation(bodyInformationId: string): Promise<ApiResult<IBodyInformation>> {
        return this.get<IBodyInformation>(`/${appConfig.proxy}/bodyinformation/${bodyInformationId}`);
    }

    public async deleteBodyInformation(bodyInformationId: number): Promise<ApiResult<void>> {
        return this.delete(`/${appConfig.proxy}/bodyinformation/${bodyInformationId}`);
    }

    public async updateBodyInformation(bodyInformation: IBodyInformationModelUpdate): Promise<ApiResult<void>> {
        return this.put<IBodyInformationModelUpdate,void>(`/${appConfig.proxy}/bodyinformation`, bodyInformation);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
export const BodyInformationService = new BodyInformationServiceAPI(TimeoutMilliseconds);