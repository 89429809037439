import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from './config.service';
import HttpServiceCommonResult from "shared/networking/http_service_common_result";
import { ITranslationValue } from "models/translation/translation_value";

class CharacteristicValueAPI extends HttpServiceCommonResult {
    public async getCharacteristicTypeTranslations(): Promise<ApiResult<ITranslationValue[]>> {
        return this.get<ITranslationValue[]>(`/${appConfig.proxy}/characteristicvalue/translation`);
    }  
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const CharacteristicValueService = new CharacteristicValueAPI(TimeoutMilliseconds);

export default CharacteristicValueService;
