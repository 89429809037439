import HttpServiceCommonResult from 'shared/networking/http_service_common_result';
import { MinMaxRule } from '../models/plausibility_check/min_max_rule';
import { MinMaxRuleCreate } from '../models/plausibility_check/min_max_rules_create';
import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from './config.service';

class MinMaxRuleServiceAPI extends HttpServiceCommonResult {
    public async getMinMaxRules(checkId: number): Promise<ApiResult<Array<MinMaxRule>>> {
        return this.get<Array<MinMaxRule>>('/${appConfig.proxy}/plausibilityminmax/' + checkId + '/rule');
    }

    public async saveMinMaxRules(data: MinMaxRuleCreate): Promise<ApiResult<void>> {
        return this.post<MinMaxRuleCreate,void>(`/${appConfig.proxy}/plausibilityminmax/rule`, data);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const MinMaxRuleService = new MinMaxRuleServiceAPI(TimeoutMilliseconds);

export default MinMaxRuleService;
