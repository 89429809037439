import IMasspointEdit from "models/masspoints/masspoint_edit";
import { IMasspoint } from "../models/masspoints/masspoint";
import IMasspointOverview from "../models/masspoints/masspoint_overview";
import { MassPointReferences, UpdateMassPointReferences } from '../models/masspoints/masspoint_references';
import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from "./config.service";
import HttpServiceCommonResult from "shared/networking/http_service_common_result";
import { CommonApiResult } from "shared/networking/common_api_result";

class MassPointServiceAPI extends HttpServiceCommonResult {
    public async saveMasspoint(masspoint: IMasspoint): Promise<ApiResult<void>> {
        return this.post<IMasspoint,void>(`/${appConfig.proxy}/masspoint`, masspoint);
    }

    public async getMassPoints(): Promise<ApiResult<Array<IMasspointOverview>>> {
        return this.get<Array<IMasspointOverview>>(`/${appConfig.proxy}/masspoint`);
    }

    public async getMassPointsForBodyArea(bodyAreaId?: number): Promise<ApiResult<Array<IMasspointOverview>>> {
        var query = `/${appConfig.proxy}/masspoint/filter`;

        if (bodyAreaId != null && bodyAreaId != undefined) {
            query += `?bodyAreaId=${bodyAreaId}`
        }

        return this.get<Array<IMasspointOverview>>(query);

    }

    public async deleteMassPoint(masspointId: Number): Promise<ApiResult<void>> {
        return this.delete(`/${appConfig.proxy}/masspoint/${masspointId}`);
    }

    public async getMassPoint(masspointId: Number): Promise<ApiResult<IMasspointOverview>> {
        return this.get<IMasspointOverview>(`/${appConfig.proxy}/masspoint/${masspointId}`);
    }

    public async updateReferences(references: UpdateMassPointReferences): Promise<ApiResult<void>> {
        return this.put<UpdateMassPointReferences,void>(`/${appConfig.proxy}/masspoint`, references);
    }

    public async updateMassPoint(updatedMasspoint: IMasspointEdit): Promise<ApiResult<void>> {
        return this.put<IMasspointEdit,void>(`/${appConfig.proxy}/masspoint/${updatedMasspoint.massPointId}`, updatedMasspoint);
    }

    public async getMassPointReferences(masspointId: Number): Promise<ApiResult<MassPointReferences>> {
        return this.get<MassPointReferences>(`/${appConfig.proxy}/masspoint/${masspointId}/references`);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const MassPointService = new MassPointServiceAPI(TimeoutMilliseconds);

export default MassPointService;
