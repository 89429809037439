import { NameClaim } from "models/authentication/authentication";
import { useAuth } from "../../shared/providers/auth_provider";
import { PrimaryButtonComponent } from "../../shared/shared";
import * as style from "./account_area.style";

const AccountAreaComponent = () => {
  const { authData, signOut} = useAuth();
  const humanName = authData.claims.find(cla => cla.type == NameClaim);

  return (
    <style.AccountAreaBox>
      <style.AccountInfoBox>
        <style.AccountNameBox>
          <style.AccountName>{humanName ? humanName.value : ''}</style.AccountName>
          <style.AccountUserName>{authData.name}</style.AccountUserName>
        </style.AccountNameBox>
      </style.AccountInfoBox>
      <PrimaryButtonComponent onClick={() => signOut()}>
        Logout
      </PrimaryButtonComponent>
    </style.AccountAreaBox>
  );
};

export default AccountAreaComponent;