import HttpServiceCommonResult from "shared/networking/http_service_common_result";
import { ICheckTypeMessages } from "../models/plausibility_check/check_type_messages";
import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from './config.service';

class CheckTypeMessageServiceAPI extends HttpServiceCommonResult {
    public async getMessages(): Promise<ApiResult<ICheckTypeMessages>> {
        return this.get<ICheckTypeMessages>(`/${appConfig.proxy}/checktypemessage`);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const CheckTypeMessageService = new CheckTypeMessageServiceAPI(TimeoutMilliseconds);

export default CheckTypeMessageService;
