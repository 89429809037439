import { IPositionCategoryOverview } from "models/position_category/position_category_overview";
import { ApiResult } from "shared/networking/api_result";
import { getConfig } from "./config.service";
import { IPositionCategory } from "models/position_category/position_category";
import { IPositionCategoryCreateModel } from "models/position_category/position_category_create";
import { IPositionCategoryEditModel } from "models/position_category/position_category_edit";
import HttpServiceCommonResult from "shared/networking/http_service_common_result";

class PositionCategoryServiceAPI extends HttpServiceCommonResult {
  public async getPositionCategories(): Promise<ApiResult<Array<IPositionCategoryOverview>>> {
    return this.get<Array<IPositionCategoryOverview>>(`/${appConfig.proxy}/positioncategory`);
  }

  public async getPositionCategory(id: string): Promise<ApiResult<IPositionCategory>> {
    return this.get<IPositionCategory>(`/${appConfig.proxy}/positioncategory/${id}`);
  }

  public async deletePositionCategory(additionCategoryid: number): Promise<ApiResult<void>> {
    return this.delete(`/${appConfig.proxy}/positioncategory/${additionCategoryid}`);
  }

  public async createPositionCategory(positionCategory: IPositionCategoryCreateModel): Promise<ApiResult<void>> {
    return this.post<IPositionCategoryCreateModel,void>(`/${appConfig.proxy}/positioncategory`, positionCategory);
  }

  public async updatePositionCategory(positionCategory: IPositionCategoryEditModel): Promise<ApiResult<void>> {
    return this.put<IPositionCategoryEditModel,void>(`/${appConfig.proxy}/positioncategory`, positionCategory);
  }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const PositionCategoryService = new PositionCategoryServiceAPI(TimeoutMilliseconds);

export default PositionCategoryService;