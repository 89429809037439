import { ApiResult } from "./api_result";
import { fetchWithTimeout } from "./fetch_with_timeout";
import { getConfig } from "services/config.service";
import { isNullOrWhitespace } from "../../services/validation.service";

class HttpServiceCommonResult {
  timeoutMs: number;

  constructor(timeoutMs?: number) {
    if (timeoutMs) {
      this.timeoutMs = timeoutMs;
    } else {
      const appConfig = getConfig();
      this.timeoutMs = Number(appConfig.httpTimeoutMs);
    }
    }

    private buildPath(path: string): string {
        // todo when working remove me 
        const backendUrl = "";
        let url = path;
        if (isNullOrWhitespace(backendUrl)) {
            url = backendUrl + path;
        }
        return url;
    }

  protected async uploadFileForm<ReturnType>(path: string, content: FormData): Promise<ApiResult<ReturnType>> {
    try {
      const response = await fetchWithTimeout(this.buildPath(path), {
        method: "POST",
        body: content,
        timeout: this.timeoutMs,
      });

      const result = new ApiResult<ReturnType>().withStatusCode(response.status);
      if (response.ok) {
        const data = await response.json();
        result.withData(data);
      }
      return result;
    } catch (error) {
      console.warn(`HTTP timeout after ${this.timeoutMs} ms`)
      return new ApiResult<ReturnType>().withTimeout();
    }
  }

  protected async editFileForm<ReturnType>(path: string, content: FormData): Promise<ApiResult<ReturnType>> {
    try {
        const response = await fetchWithTimeout(this.buildPath(path), {
        method: "PUT",
        body: content,
        timeout: this.timeoutMs,
      });

      const result = new ApiResult<ReturnType>().withStatusCode(response.status);
      if (response.ok) {
        const data = await response.json();
        result.withData(data);
      }
      return result;

    } catch (error) {
      console.warn(`HTTP timeout after ${this.timeoutMs} ms`)
      return new ApiResult<ReturnType>().withTimeout();
    }
  }

  protected async get<ReturnType>(path: string): Promise<ApiResult<ReturnType>> {
      try {
        const response = await fetchWithTimeout(this.buildPath(path), {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        timeout: this.timeoutMs,
      });
      const result = new ApiResult<ReturnType>().withStatusCode(response.status);
      if (response.ok) {
        const data = await response.json();
        result.withData(data);
      }
      return result;
    } catch (error) {
      console.warn(`HTTP timeout after ${this.timeoutMs} ms`)
      return new ApiResult<ReturnType>().withTimeout();
    }
  }

  protected async getAnonym<ReturnType>(path: string): Promise<ApiResult<ReturnType>> {
        try {
            const response = await fetchWithTimeout(this.buildPath(path), {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            timeout: this.timeoutMs,
          });
          const result = new ApiResult<ReturnType>().withStatusCode(response.status);
          if (response.ok) {
            const data = await response.json();
            result.withData(data);
          }
          return result;
        } catch (error) {
          console.warn(`HTTP timeout after ${this.timeoutMs} ms`)
          return new ApiResult<ReturnType>().withTimeout();
        }
    }

    protected async postNoData<ReturnType>(path: string): Promise<ApiResult<ReturnType>> {
        try {
            const response = await fetchWithTimeout(this.buildPath(path), {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
                timeout: this.timeoutMs,
            });

            const result = new ApiResult<ReturnType>().withStatusCode(response.status);
            if (response.ok) {
                result.withData(await response.json());
            }
            return result;
        } catch (error) {
            console.warn(`HTTP timeout after ${this.timeoutMs} ms`)
            return new ApiResult<ReturnType>().withTimeout();
        }
    }

  protected async post<ContentType,ReturnType>(path: string, content: ContentType): Promise<ApiResult<ReturnType>> {
    try {
        const response = await fetchWithTimeout(this.buildPath(path), {
        method: "POST",
        body: JSON.stringify(content),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        timeout: this.timeoutMs,
      });

      const result = new ApiResult<ReturnType>().withStatusCode(response.status);
      if (response.ok) {
        result.withData(await response.json());
      }
      return result;
    } catch (error) {
      console.warn(`HTTP timeout after ${this.timeoutMs} ms`)
      return new ApiResult<ReturnType>().withTimeout();
    }
  }

  protected async postForData<ReturnType>(path: string): Promise<ApiResult<ReturnType>> {
    try {
        const response = await fetchWithTimeout(this.buildPath(path), {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        timeout: this.timeoutMs,
      });
      const result = new ApiResult<ReturnType>().withStatusCode(response.status);
      if (response.ok) {       
        const data = await response.json();
        result.withData(data);
      }
      return result;
    } catch (error) {
      console.warn(`HTTP timeout after ${this.timeoutMs} ms`)
      return new ApiResult<ReturnType>().withTimeout();
    }
  }

  protected async put<ContentType,ReturnType>(path: string, content?: ContentType): Promise<ApiResult<ReturnType>> {
    try {
        const response = await fetchWithTimeout(this.buildPath(path), {
        method: "PUT",
        body: content ? JSON.stringify(content) : null,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        timeout: this.timeoutMs,
      });

      const result = new ApiResult<ReturnType>().withStatusCode(response.status);
      if (response.ok) {
        result.withData(await response.json());
      }
      return result;
    } catch (error) {
      console.warn(`HTTP timeout after ${this.timeoutMs} ms`)
      return new ApiResult<ReturnType>().withTimeout();
    }
  }

  protected async delete(path: string): Promise<ApiResult<void>> {
    try {
        const response = await fetchWithTimeout(this.buildPath(path), {
        method: "DELETE",
        timeout: this.timeoutMs,
      });

      const result = new ApiResult<void>().withStatusCode(response.status);
      if (response.ok) {
        result.withData(await response.json());
      }

      return result;
    } catch (error) {
      console.warn(`HTTP timeout after ${this.timeoutMs} ms`)
      return new ApiResult<void>().withTimeout();
    }
  }
}

export default HttpServiceCommonResult;
