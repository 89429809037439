import { IPreposition } from "models/preposition/preposition";
import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from './config.service';
import HttpServiceCommonResult from "shared/networking/http_service_common_result";

class PrepositionServiceAPI extends HttpServiceCommonResult {
    public async getPrepositions(): Promise<ApiResult<IPreposition[]>> {
        return this.get<IPreposition[]>(`/${appConfig.proxy}/preposition`);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const PrepositionService = new PrepositionServiceAPI(TimeoutMilliseconds);

export default PrepositionService;