import { ISimilarPlausibilityCheckType } from "models/plausibility_check/plausability_check_type_similar";
import { IPlausibilityCheckType } from "../models/plausibility_check/plausibility_check_type";
import { ICreatePlausibilityCheckType } from '../models/plausibility_check/plausibility_check_type_create';
import { IEditPlausibilityCheckType } from "../models/plausibility_check/plausibility_check_type_edit";
import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from './config.service';
import HttpServiceCommonResult from "shared/networking/http_service_common_result";

class CheckTypeServiceAPI extends HttpServiceCommonResult {
    public async getCheckTypes(): Promise<ApiResult<Array<IPlausibilityCheckType>>> {
        return this.get<Array<IPlausibilityCheckType>>(`/${appConfig.proxy}/plausibilitychecktype`);
    }

    public async getCheckType(id: number): Promise<ApiResult<IPlausibilityCheckType>> {
        return this.get<IPlausibilityCheckType>(`/${appConfig.proxy}/plausibilitychecktype/${id}`)
    }

    public async saveCheckType(checkType: ICreatePlausibilityCheckType): Promise<ApiResult<void>> {
        return this.post<ICreatePlausibilityCheckType, void>(`/${appConfig.proxy}/plausibilitychecktype`, checkType);
    }

    public async updateCheckType(checkType: IEditPlausibilityCheckType): Promise<ApiResult<void>> {
        return this.put<IEditPlausibilityCheckType, void>(`/${appConfig.proxy}/plausibilitychecktype`, checkType);
    }

    public async deleteCheckType(id: number): Promise<ApiResult<void>> {
        return this.delete(`/${appConfig.proxy}/plausibilitychecktype/${id}`);
    }

    public async getSimilarPlausibilityCheckTypes(checkId: number) : Promise<ApiResult<Array<ISimilarPlausibilityCheckType>>> {
        return this.get(`/${appConfig.proxy}/plausibilitychecktype/check/${checkId}`);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const CheckTypeService = new CheckTypeServiceAPI(TimeoutMilliseconds);

export default CheckTypeService;
