import HttpServiceCommonResult from 'shared/networking/http_service_common_result';
import ICheckHistoryDetails from '../models/checks_history/check_history_details';
import ICheckHistoryOverview from '../models/checks_history/check_history_overview';
import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from './config.service';

class CheckHistoryServiceAPI extends HttpServiceCommonResult {
    public async getChecksHistoryOverview(): Promise<ApiResult<Array<ICheckHistoryOverview>>> {
        return this.get<Array<ICheckHistoryOverview>>(`/api/plausibilitycheckhistory`);
    }

    public async getCheckHistoryDetails(historyId: number): Promise<ApiResult<ICheckHistoryDetails>> {
        return this.get<ICheckHistoryDetails>(`/${appConfig.proxy}/plausibilitycheckhistory/${historyId}`);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const MassMaskHistoryService = new CheckHistoryServiceAPI(TimeoutMilliseconds);

export default MassMaskHistoryService;
