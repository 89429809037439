import { ICheckUpdate } from 'models/plausibility_check/check_update';
import { ICheckCreate } from '../models/plausibility_check/check_create';
import ICheckDuplicate from '../models/plausibility_check/check_duplicate';
import { ICheckOverview } from '../models/plausibility_check/check_overview';
import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from './config.service';
import HttpServiceCommonResult from 'shared/networking/http_service_common_result';

class CheckServiceAPI extends HttpServiceCommonResult {
    public async getCheck(id: number): Promise<ApiResult<ICheckOverview>> {
        return this.get<ICheckOverview>(`/${appConfig.proxy}/plausibilitycheck/${id}`);
    }

    public async getCheckByVersion(id: number): Promise<ApiResult<ICheckOverview>> {
        return this.get<ICheckOverview>(`/${appConfig.proxy}/plausibilitycheck/version/${id}/active`);
    }

    public async getChecks(): Promise<ApiResult<Array<ICheckOverview>>> {
        return this.get<Array<ICheckOverview>>(`/${appConfig.proxy}/plausibilitycheck`);
    }

    public async saveCheck(check: ICheckCreate): Promise<ApiResult<number>> {
        return this.post<ICheckCreate,number>(`/${appConfig.proxy}/plausibilitycheck`, check);
    }

    public async deleteCheck(id: number): Promise<ApiResult<void>> {
        return this.delete(`/${appConfig.proxy}/plausibilitycheck/${id}`);
    }

    public async duplicateCheck(data: ICheckDuplicate): Promise<ApiResult<number>> {
        return this.post<ICheckDuplicate,number>(`/${appConfig.proxy}/plausibilitycheck/duplicate`, data);
    }

    public async publishCheck(check: ICheckOverview): Promise<ApiResult<void>> {
        return this.put<void,void>(`/${appConfig.proxy}/plausibilitycheck/${check.id}/publish`);
    }

    public async unpublishCheck(check: ICheckOverview): Promise<ApiResult<void>> {
        return this.put<void,void>(`/${appConfig.proxy}/plausibilitycheck/${check.id}/unpublish`);
    }

    public async updateCheck(data: ICheckUpdate): Promise<ApiResult<void>> {
        return this.put<ICheckUpdate,void>(`/${appConfig.proxy}/plausibilitycheck/update`, data);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const PlausibilityCheckService = new CheckServiceAPI(TimeoutMilliseconds);

export default PlausibilityCheckService;
