import { ICharacteristicTypeOverview } from "models/characteristic_types/characteristic_type_overview";
import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from './config.service';
import HttpServiceCommonResult from "shared/networking/http_service_common_result";
import { ICharacteristicTypeCreate, ICharacteristicTypeUpdate } from "models/characteristic_types/characteristic_type_create";
import { ICharacteristicType } from "models/characteristic_types/characteristic_type";

class CharacteristicTypeAPI extends HttpServiceCommonResult {
    public async getCharacteristicType(id: string): Promise<ApiResult<ICharacteristicType>> {
        return this.get<ICharacteristicType>(`/${appConfig.proxy}/characteristictype/${id}`);
    }  

    public async getCharacteristicTypes(): Promise<ApiResult<Array<ICharacteristicTypeOverview>>> {
        return this.get<Array<ICharacteristicTypeOverview>>(`/${appConfig.proxy}/characteristictype`);
    }
    
    public async createCharacteristicTypes(data: ICharacteristicTypeCreate): Promise<ApiResult<void>> {
        return this.post<ICharacteristicTypeCreate,void>(`/${appConfig.proxy}/characteristictype`, data);
    } 

    public async deleteCharacteristicType(id: number): Promise<ApiResult<void>> {
        return this.delete(`/${appConfig.proxy}/characteristictype/${id}`);
    }

    public async updateCharacteristicTypes(data: ICharacteristicTypeUpdate): Promise<ApiResult<void>> {
        return this.put<ICharacteristicTypeUpdate,void>(`/${appConfig.proxy}/characteristictype`, data);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const CharacteristicTypeService = new CharacteristicTypeAPI(TimeoutMilliseconds);

export default CharacteristicTypeService;
