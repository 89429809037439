import { IAdditionHierarchy } from "models/addition_hierarchy/addition_hierarchy";
import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from './config.service';
import HttpServiceCommonResult from "shared/networking/http_service_common_result";

class AdditionHierarchyServiceAPI extends HttpServiceCommonResult {
    public async getAdditionHierarchies(): Promise<ApiResult<IAdditionHierarchy[]>> {
        return this.get<IAdditionHierarchy[]>(`/${appConfig.proxy}/additionhierarchy`);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const AdditionHierarchyService = new AdditionHierarchyServiceAPI(TimeoutMilliseconds);

export default AdditionHierarchyService;