import { Grid } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { EditorButtonComponent } from "shared/components/editors/editor_button/editor_buttons.component";
import { LoadingOverlayContainer, LoadingOverlayComponent } from "shared/components/loading_overlay/loading_overlay.component";
import { LoadingIndicatorComponent, TextBoxComponent } from "shared/shared";
import { resetState, editMasspointCompleted, setEditorMode, updateIdentifier, updateNameTranslationKey, cancelEditor, selectMassPointCriterias, unSelectMassPointCriterias, filterUnselectedMassPointCriterias, filterSelectedMassPointCriterias, saveMasspointCompleted } from "./redux/masspoint_group.slice";
import * as style from "./masspoint_group.style";
import { editMasspointGroup, getMasspointGroup, getMasspoints, saveMasspointGroup } from "./redux/masspoint_group.thunks";
import { isNullOrWhitespace } from "services/validation.service";
import { SelectionListComponent } from "shared/components/selectionComponent/selectionList.component";
import { POEditorLinkComponent } from "shared/components/editors/poeditor_link/poeditor_link.component";
import { getConfig } from "services/config.service";
import { EditorModeEnum } from "models/editors/editor_mode.enum";

export const MassPointGroupComponent = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const state = useAppSelector((state) => state.masspoint_groups);
  const { masspointGroupId } = useParams();

  useEffect(() => {
    if (masspointGroupId) {
      dispatch(setEditorMode(EditorModeEnum.Edit));
      dispatch(getMasspointGroup(Number(masspointGroupId)));
    }
    else{
      dispatch(getMasspoints());
    }
    return () => {
      dispatch(resetState());
    }
  }, [dispatch, masspointGroupId]);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  if (state.command.cancelMasspoint.status === "success") {
    navigate("/masspoints/masspointgroup/overview");
    dispatch(resetState());
  }

  if (state.actualData.editorMode === EditorModeEnum.Edit &&
    (state.query.getMasspointGroup.status === "idle" ||
      state.query.getMasspointGroup.status === "pending")) {
    return <LoadingIndicatorComponent />;
  }

  if (state.command.editMasspointGroup.status === "success" || 
    state.command.saveMasspointGroup.status === "success") {
    enqueueSnackbar("Maßpunktgruppe gespeichert", { variant: "success" });
    navigate("/masspoints/masspointgroup/overview");
    dispatch(resetState());
  }

  if (state.query.getMasspointGroup.status === "error") {
    enqueueSnackbar(state.query.getMasspointGroup.message, { variant: "error" });
    navigate("/masspoints/masspointgroup/overview");
    dispatch(resetState());
  }  
  
  if (state.query.getMasspoints.status === "error") {
    enqueueSnackbar(state.query.getMasspoints.message, { variant: "error" });
    navigate("/masspoints/masspointgroup/overview");
    dispatch(resetState());
  }

  if (state.command.editMasspointGroup.status === "error") {
    enqueueSnackbar(state.command.editMasspointGroup.message, { variant: "error" });
    dispatch(editMasspointCompleted());
  } 
  
  if (state.command.saveMasspointGroup.status === "error") {
    enqueueSnackbar(state.command.saveMasspointGroup.message, { variant: "error" });
    dispatch(saveMasspointCompleted());
  }

  const onSaveMassPointGroup = () => {
    switch (state.actualData.editorMode) {
      case EditorModeEnum.Edit:
        dispatch(editMasspointGroup())
        break;
      case EditorModeEnum.Create:
        dispatch(saveMasspointGroup())
        break;
    }
  }

  const isSaving = state.command.editMasspointGroup.status === "pending";

  const canSave = !isNullOrWhitespace(state.actualData.masspointGroup.identifier)
    && !isNullOrWhitespace(state.actualData.masspointGroup.nameTranslationKey)
    && state.actualData.masspointGroup.massPointCriterias.selectedList.allItems.length > 0
    && state.command.editMasspointGroup.canExecute
    && state.command.saveMasspointGroup.canExecute;

  return (
    <>
      <LoadingOverlayContainer>
        {isSaving ? <LoadingOverlayComponent /> : <></>}
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start">
          <Grid item md={12} marginBottom={"20px"}>
            <TextBoxComponent
              name="Bezeichner"
              value={state.actualData.masspointGroup?.identifier}
              onChange={(value) => { dispatch(updateIdentifier(value)) }}
              isRequired={true}
              isAutoFocus={true}
            />
          </Grid>
          <Grid item md={12} marginBottom={"20px"}>
            <TextBoxComponent
              name="Übersetzungs-Key für Name"
              value={state.actualData.masspointGroup?.nameTranslationKey}
              onChange={(value) => { dispatch(updateNameTranslationKey(value)) }}
              isRequired={true}
            />
          </Grid>
          <POEditorLinkComponent/>
          <Grid item md={12} marginTop={"20px"}>
            <SelectionListComponent
              title={"Zugeordnete Masspunkte"}
              entityName={"Masspunkte"}
              onFilterSelected={(searchText) => { dispatch(filterSelectedMassPointCriterias(searchText))}}
              onFilterUnselected={(searchText) => { dispatch(filterUnselectedMassPointCriterias(searchText))}}
              selectionList={state.actualData.masspointGroup.massPointCriterias}
              selectEntities={(ids) => { dispatch(selectMassPointCriterias(ids)) }}
              unselectEntities={(ids) => { dispatch(unSelectMassPointCriterias(ids)) }}
            />
          </Grid>
        </Grid>
        <style.InfoText>Mit * gekennzeichnete Felder sind Pflichtfelder.</style.InfoText>
        <EditorButtonComponent
          canExecute={canSave}
          save={onSaveMassPointGroup}
          cancelSave={() => dispatch(cancelEditor())}
        />
      </LoadingOverlayContainer>
    </>
  );
};

