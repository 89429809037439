import { Grid, IconButton, Typography } from "@mui/material";
import { ListButtonComponent } from "shared/components/buttons/list_button.component";
import { EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";
import { TextBoxComponent } from "shared/shared";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { doesExist } from "services/validation.service";
import { getConfig } from "services/config.service";
import { POEditorLinkComponent } from "../../../shared/components/editors/poeditor_link/poeditor_link.component";
import { useAppSelector, useAppDispatch } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { changeShortKey, changeValueName, changeValueKey, addValue, deleteValue, changeName } from "../redux/characteristic_type.slice";
import { EditorModeEnum } from "models/editors/editor_mode.enum";

export const CharacteristicTypeEditorComponent: React.FC = () => {

    const state = useAppSelector((state) => state.characteristic_types);
    const dispatch: AppDispatch = useAppDispatch();

    const values = state.data.characteristicType.values;
    const translations = state.loadedData.characteristicValueTranslations;

    const getTranslation = (key: string): string => {
        const translation = translations.filter(x => x.name === key);

        return translation.length >= 1 ? translation[0].translation : undefined;
    }

    const disabledAddButton = values.filter(x => x.name === '' || x.translationKey === '').length > 0;

    return (
        <Grid container spacing={3} direction={'column'}>
            <Grid item >
                <EditorSectionHeaderComponent>
                    {state.data.editorMode === EditorModeEnum.Edit ? "Ausprägungstyp editieren" : "Neuen Ausprägungstyp erstellen"}
                </EditorSectionHeaderComponent>
            </Grid>
            <Grid item>
                <TextBoxComponent
                    name="Name"
                    value={state.data.characteristicType.name}
                    onChange={(value) => dispatch(changeName(value))}
                    isRequired={true}
                    maxLength={512}
                    isAutoFocus={true}
                />
                <TextBoxComponent
                    name="Kürzel"
                    value={state.data.characteristicType.shortKey}
                    onChange={(value) => dispatch(changeShortKey(value))}
                    isRequired={true}
                    minLength={3}
                    maxLength={3}
                    isAutoFocus={false}
                />
            </Grid>
            <Grid item>
                <EditorSectionHeaderComponent>Ausprägungswerte</EditorSectionHeaderComponent>
            </Grid>
            <Grid item direction={'column'}>
                {
                    values.map((value, index) => {
                        return (
                            <>
                                <Grid item key={`value_${index}`}
                                    sx={{ marginBottom: '20px' }} direction={'row'}>
                                    <TextBoxComponent
                                        name="Wert"
                                        value={value.name}
                                        onChange={(value) => dispatch(changeValueName({ index, value }))}
                                        isRequired={true}
                                        maxLength={512}
                                        isAutoFocus={true}
                                    />
                                    <TextBoxComponent
                                        name="Übersetzungskey"
                                        value={value.translationKey}
                                        onChange={(value) => dispatch(changeValueKey({ index, value }))}
                                        isRequired={true}
                                        maxLength={100}
                                        isAutoFocus={false}
                                    />
                                    <IconButton
                                        style={{ outline: "none" }}
                                        onClick={() => dispatch(deleteValue(index))}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                                {doesExist(getTranslation(value.translationKey)) &&
                                    <Grid item key={`translation_${index}`} sx={{ marginBottom: '20px' }}>
                                        <Typography>
                                            {getTranslation(value.translationKey)}
                                        </Typography>
                                    </Grid>
                                }
                            </>
                        );
                    })
                }
            </Grid>
            {values.length > 0 && <POEditorLinkComponent/>}
            <Grid item md={6} direction={'row'}>
                <ListButtonComponent style={{ width: '50%' }}
                    onClick={()=> dispatch(addValue())}
                    disabled={disabledAddButton}>
                    <AddIcon /> Wert hinzufügen
                </ListButtonComponent>
            </Grid>
        </Grid>
    );
};