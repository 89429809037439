import { IProductionInstructionTranslationKey } from "models/production_instructions/production_instruction_translation_key";
import { IProductionInstructionCreate } from "models/production_instructions/production_instruction_create";
import { IProductionInstructionOverview } from "models/production_instructions/production_instruction_overview";
import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from "./config.service";
import { IProductionInstructionUpdate } from "../models/production_instructions/production_instruction_update";
import HttpServiceCommonResult from "../shared/networking/http_service_common_result";

class ProductionInstructionServiceAPI extends HttpServiceCommonResult {
    public async getAll(): Promise<ApiResult<Array<IProductionInstructionOverview>>> {
        return this.get<Array<IProductionInstructionOverview>>(`/${appConfig.proxy}/productioninstruction`);
    }

    public async getById(id: string): Promise<ApiResult<IProductionInstructionUpdate>> {
        return this.get<IProductionInstructionUpdate>(`/${appConfig.proxy}/productioninstruction/${id}`);
    }

    public async getTranslationKeys(): Promise<ApiResult<Array<IProductionInstructionTranslationKey>>> {
        return this.get<Array<IProductionInstructionTranslationKey>>(`/${appConfig.proxy}/productioninstruction/messages`);
    }

    public async create(instruction: IProductionInstructionCreate): Promise<ApiResult<void>> {
        return this.post<IProductionInstructionCreate,void>(`/${appConfig.proxy}/productioninstruction`, instruction);
    }

    public async update(instruction: IProductionInstructionUpdate): Promise<ApiResult<void>> {
        return this.put<IProductionInstructionCreate,void>(`/${appConfig.proxy}/productioninstruction/${instruction.productionInstructionId}`, instruction);
    }

    public async deleteProductionInstruction(productionInstructionid: number): Promise<ApiResult<void>> {
        return this.delete(`/${appConfig.proxy}/productioninstruction/${productionInstructionid}`);
    }

    public async publish(instruction: IProductionInstructionOverview): Promise<ApiResult<void>> {
        return this.put<void,void>(`/${appConfig.proxy}/productioninstruction/${instruction.productionInstructionId}/publish`);
    }

    public async unpublish(instruction: IProductionInstructionOverview): Promise<ApiResult<void>> {
        return this.put<void,void>(`/${appConfig.proxy}/productioninstruction/${instruction.productionInstructionId}/unpublish`);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
export const ProductionInstructionService = new ProductionInstructionServiceAPI(TimeoutMilliseconds);