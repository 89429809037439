import { FormulaRuleResponse } from 'models/plausibility_check/formula_rule_response';
import { FormulaRuleContainerCreate } from '../models/plausibility_check/formula_rules_create';
import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from './config.service';
import HttpServiceCommonResult from 'shared/networking/http_service_common_result';

class FormulaRuleServiceAPI extends HttpServiceCommonResult {
    public async getFormulaRules(checkId: number): Promise<ApiResult<FormulaRuleResponse>> {
        return this.get<FormulaRuleResponse>(`/${appConfig.proxy}/plausibilityformula/` + checkId + '/rule');
    }

    public async saveFormulaRules(data: FormulaRuleContainerCreate): Promise<ApiResult<void>> {
        return this.post<FormulaRuleContainerCreate, void>(`/${appConfig.proxy}/plausibilityformula/rule`, data);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const FormulaRuleService = new FormulaRuleServiceAPI(TimeoutMilliseconds);

export default FormulaRuleService;
