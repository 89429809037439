import { useImperativeHandle, useEffect, useRef, forwardRef } from "react";
import { ComponentDataEvent, FailedDataEvent, ProductionInstructionEvaluatedEvent, ProductionInstructionLoadedEvent } from "@medi-mtm/components"
import { getConfigName } from "services/config.service";
import HttpServiceCommonResult from "shared/networking/http_service_common_result";

export type ProductionInstructionsWrapperComponentProps = {
    productConfigurationId: string;
    configurationRunId: number;
    onProductionInstructionsComponentUpdated: (productionInstructions: ComponentDataEvent) => void;
    updateProductionInstructionsEvaluating: () => {};
    updateProductionInstructionsEvaluationFailed: (detail: FailedDataEvent) => void;
    updateProductionInstructionsEvaluated: (event: ProductionInstructionEvaluatedEvent) => {};
    updateProductionInstructionsLoading: () => {};
    updateProductionInstructionsLoadingFailed: (detail: FailedDataEvent) => void;
    updateProductionInstructionsLoaded: (event: ProductionInstructionLoadedEvent) => {};
}

export const ProductionInstructionsWrapperComponent = forwardRef<any, ProductionInstructionsWrapperComponentProps>((
    {
        productConfigurationId,
        configurationRunId,
        onProductionInstructionsComponentUpdated,
        updateProductionInstructionsEvaluating,
        updateProductionInstructionsEvaluationFailed,
        updateProductionInstructionsEvaluated,
        updateProductionInstructionsLoading,
        updateProductionInstructionsLoadingFailed,
        updateProductionInstructionsLoaded,
    }, ref) => {
    const componentRef = useRef(null) as any;

    useImperativeHandle(ref, () => ({
        evaluateProductionInstructions() {
            if (componentRef.current != null) {
                const component = (componentRef.current as any);
                component.evaluate();
            }
        },
        initializeProductionInstructions() {
            initComponent();
        }
    }));

    const initComponent = async () => {
        const component = (componentRef.current);
        if (component) {
            component.init({
                Environment: getConfigName(),
                endpoints: {
                    getProductionInstructions: "/mtmapi/productConfiguration/$1/productioninstructions",
                    evaluateProductionInstructions: "/mtmapi/productconfiguration/productioninstructions",
                }
            });

            component.setProductConfigurationId(productConfigurationId);
            component.setProductConfigurationRunId(configurationRunId);

            component.load();
        }
    };

    useEffect(() => {
        const component = (componentRef.current);
        if (component) {
            if (configurationRunId) {
                component.setProductConfigurationRunId(configurationRunId);
            }
            if (productConfigurationId) {
                component.setProductConfigurationId(productConfigurationId);
            }
        }

    }, [productConfigurationId, configurationRunId])

    useEffect(() => {
        const component = (componentRef.current as any);
        const getComponentStateHandler = (event: CustomEvent<ComponentDataEvent>) => {
            onProductionInstructionsComponentUpdated(event.detail);
        };

        const productionInstructionsEvaluatingChangedHandler = (event: CustomEvent) => {
            updateProductionInstructionsEvaluating();
        }

        const productionInstructionsEvaluationFailedChangedHandler = (event: CustomEvent) => {
            updateProductionInstructionsEvaluationFailed(event.detail);
        }

        const productionInstructionsEvaluatedChangedHandler = (event: CustomEvent<ProductionInstructionEvaluatedEvent>) => {
            updateProductionInstructionsEvaluated(event.detail);
        }

        const productionInstructionsLoadingChangedHandler = () => {
            updateProductionInstructionsLoading();
        }

        const productionInstructionsLoadingFailedChangedHandler = (event: CustomEvent) => {
            updateProductionInstructionsLoadingFailed(event.detail);
        }

        const productionInstructionsLoadedChangedHandler = (event: CustomEvent<ProductionInstructionLoadedEvent>) => {
            updateProductionInstructionsLoaded(event.detail);
        }

        if (component !== null) {
            component.addEventListener("getComponentState", getComponentStateHandler);
            component.addEventListener("productionInstructionsEvaluating", productionInstructionsEvaluatingChangedHandler);
            component.addEventListener("productionInstructionsEvaluationFailed", productionInstructionsEvaluationFailedChangedHandler);
            component.addEventListener("productionInstructionsEvaluated", productionInstructionsEvaluatedChangedHandler);
            component.addEventListener("productionInstructionsLoading", productionInstructionsLoadingChangedHandler);
            component.addEventListener("productionInstructionsLoadingFailed", productionInstructionsLoadingFailedChangedHandler);
            component.addEventListener("productionInstructionsLoaded", productionInstructionsLoadedChangedHandler);
        }
        return () => {
            if (component !== null) {
                component.removeEventListener("getComponentState", getComponentStateHandler);
                component.removeEventListener("productionInstructionsEvaluating", productionInstructionsEvaluatingChangedHandler);
                component.removeEventListener("productionInstructionsEvaluationFailed", productionInstructionsEvaluationFailedChangedHandler);
                component.removeEventListener("productionInstructionsEvaluated", productionInstructionsEvaluatedChangedHandler);
                component.removeEventListener("productionInstructionsLoading", productionInstructionsLoadingChangedHandler);
                component.removeEventListener("productionInstructionsLoadingFailed", productionInstructionsLoadingFailedChangedHandler);
                component.removeEventListener("productionInstructionsLoaded", productionInstructionsLoadedChangedHandler);
            }
        }
    }, [])

    return (
        <>
            {
                <x-productioninstruction
                    ref={componentRef}
                ></x-productioninstruction>
            }
        </>
    );
})