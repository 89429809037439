import HttpServiceCommonResult from "shared/networking/http_service_common_result";
import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from "./config.service";
import { IAvailableAdditionAttributeCategory } from "models/addition_attribute_categories/available_addition_attribute_category";
import { PolicyTypeEnum } from "models/policies/policy_type.enum";
import { config } from "process";

class AdditionAttributeCategoryServiceAPI extends HttpServiceCommonResult {
    public async getAvailableAttributeCategories(): Promise<ApiResult<Array<IAvailableAdditionAttributeCategory>>> {
        return this.get<Array<IAvailableAdditionAttributeCategory>>(`/${appConfig.proxy}/availableattributecategories`);
    }

    public async getAvailableAttributeCategoriesAndAttributesByPolicy(policyType: PolicyTypeEnum, ignoredPolicy?: string ): Promise<ApiResult<Array<IAvailableAdditionAttributeCategory>>> {
        let url = `/${appConfig.proxy}/availableattributecategories/availableattributecategoriesandattributes/${policyType}`;
        if (ignoredPolicy) {
          url = url + `?ignoredPolicy=${ignoredPolicy}`;
        }
        return this.get<Array<IAvailableAdditionAttributeCategory>>(url);
    }   
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const AdditionAttributeCategoryService = new AdditionAttributeCategoryServiceAPI(TimeoutMilliseconds);

export default AdditionAttributeCategoryService;