import IAdditionOverview from "../models/additions/addition_overview";
import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from "./config.service";
import IAddition from '../models/additions/addition';
import IAdditionUpdate from 'models/additions/addition_update';
import IAdditionCreate from 'models/additions/addition_create';
import { IAdditionBaseDataResult } from '../models/additions/additionBaseData';
import { PolicyTypeEnum } from "models/policies/policy_type.enum";
import { IAdditionPolicyInformation } from "models/additions/addition_policy_information";
import HttpServiceCommonResult from "shared/networking/http_service_common_result";

class AdditionServiceAPI extends HttpServiceCommonResult {
  public async getAdditionsOverview(): Promise<ApiResult<Array<IAdditionOverview>>> {
    return this.get<Array<IAdditionOverview>>(`/${appConfig.proxy}/addition`);
  }

  public async getAvailableAdditionsByPolicyTypeOverview(policyType: PolicyTypeEnum, ignoredPolicy?: string): Promise<ApiResult<Array<IAdditionPolicyInformation>>> {
    let url = `/${appConfig.proxy}/addition/availablecreation/${policyType}`;
    if (ignoredPolicy) {
      url = url + `?ignoredPolicy=${ignoredPolicy}`;
    }

    return this.get<Array<IAdditionPolicyInformation>>(url);
  }

  public async getAddition(additionid: string): Promise<ApiResult<IAddition>> {
    return this.get<IAddition>(`/${appConfig.proxy}/addition/${additionid}`);
  }

  public async deleteAddition(additionid: number): Promise<ApiResult<void>> {
    return this.delete(`/${appConfig.proxy}/addition/${additionid}`);
  }

  public async publishAddition(addition: IAdditionOverview): Promise<ApiResult<void>> {
    return this.put<void,void>(`/${appConfig.proxy}/addition/${addition.id}/publish`);
  }

  public async getAdditionDefinition(optionNumber: string): Promise<ApiResult<IAdditionBaseDataResult>> {
    return this.get<IAdditionBaseDataResult>(`/${appConfig.proxy}/addition/erp/${optionNumber}`);
  }

  public async unpublishAddition(addition: IAdditionOverview): Promise<ApiResult<void>> {
    return this.put<void,void>(`/${appConfig.proxy}/addition/${addition.id}/unpublish`);
  }

  public async createAddition(addition: IAdditionCreate): Promise<ApiResult<void>> {
    return this.post<IAdditionCreate,void>(`/${appConfig.proxy}/addition`, addition);
  }

  public async updateAddition(addition: IAdditionUpdate): Promise<ApiResult<void>> {
    return this.put<IAdditionUpdate,void>(`/${appConfig.proxy}/addition`, addition);
  }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const AdditionService = new AdditionServiceAPI(TimeoutMilliseconds);

export default AdditionService;