import { useImperativeHandle, useEffect, useRef, forwardRef } from "react";
import { SideSelectionEnum } from '../../../models/simulator/side_selection.enum';
import { ComponentDataEvent, FailedDataEvent, MassMaskEvaluatedEvent, MassMaskLoadedEvent, Massmask } from "@medi-mtm/components";
import { ProcessTypeEnum } from "models/simulator/process_type.enum";
import { getConfigName } from "services/config.service";
import { isNullOrWhitespace } from "services/validation.service";
import HttpServiceCommonResult from "shared/networking/http_service_common_result";

export type MassmaskWrapperComponentProps = {
    mainProductLineErpId: string;
    bodyAreaErpId: string;
    articleTypeErpId: string;
    qualityErpId?: string;
    footOptionErpId?: number;
    productConfigurationId?: string;
    productConfigurationRunId?: number;
    selectedSide: SideSelectionEnum;
    processType: ProcessTypeEnum;
    diagnoseModeEnabled: boolean;
    useUnpublished: boolean;
    updateShowEvaluationResultInMassmask: boolean;
    updateMassMaskEvaluating: () => {};
    updateMassMaskEvaluationFailed: (detail: FailedDataEvent) => void;
    updateMassMaskEvaluated: (event: MassMaskEvaluatedEvent) => void;
    updateMassMaskLoading: () => {};
    updateMassMaskLoadingFailed: (detail: FailedDataEvent) => void;
    updateMassMaskLoaded: (restoreMassMask: MassMaskLoadedEvent) => void;
    onMassMaskComponentUpdated: (event: ComponentDataEvent) => void;
    showMassMask: boolean;
}

export const MassmaskWrapperComponent = forwardRef<any, MassmaskWrapperComponentProps>(({
    mainProductLineErpId,
    bodyAreaErpId,
    articleTypeErpId,
    qualityErpId,
    footOptionErpId,
    selectedSide,
    productConfigurationId,
    productConfigurationRunId,
    processType,
    diagnoseModeEnabled,
    useUnpublished,
    updateShowEvaluationResultInMassmask,
    updateMassMaskEvaluating,
    updateMassMaskEvaluationFailed,
    updateMassMaskEvaluated,
    updateMassMaskLoading,
    updateMassMaskLoadingFailed,
    updateMassMaskLoaded,
    onMassMaskComponentUpdated,
    showMassMask
}, ref) => {
    const massmaskRef = useRef(null) as any;

    useImperativeHandle(ref, () => ({
        evaluate() {
            if (massmaskRef.current != null) {
                const massmask = (massmaskRef.current as Massmask);
                massmask.evaluate();
            }
        },
        initializeMassMask() {
            initComponent();
        }
    }));

    const initComponent = async () => {
        const massmask = (massmaskRef.current as Massmask);
        if (massmask != null) {
            massmask.init({
                Environment: getConfigName(),
                endpoints: {
                    getMassmask: "/mtmapi/productconfiguration/massmask",
                    evaluateMassmask: "/mtmapi/productconfiguration",
                    translations: "/mtmapi/localization/{{ns}}?language={{lng}}"
                }
            })

            if (!isNullOrWhitespace(productConfigurationId)) {
                massmask.setProductConfigurationId(productConfigurationId);
            }
            if (productConfigurationRunId) {
                massmask.setProductConfigurationRunId(productConfigurationRunId);
            } else {
                massmask.setProductConfigurationRunId(-1);
            }

            massmask.setDiagnoseModeEnabled(diagnoseModeEnabled);
            massmask.setMainProductLineErpId(mainProductLineErpId);
            massmask.setBodyAreaErpId(bodyAreaErpId);
            massmask.setArticleTypeErpId(articleTypeErpId);
            if (qualityErpId) {
                massmask.setQualityErpId(qualityErpId);
            }
            massmask.setFootType(footOptionErpId);
            massmask.setSelectedSide(getStringofEnum(selectedSide));
            massmask.setIsMassmaskVisible(true);
            massmask.setSystem("Simulator");
            massmask.enableMeasurementAuthors();
            massmask.setProcessType(getStringofProcessTypeEnum(processType));
            massmask.load();
        }
    };

    useEffect(() => {
        const massmask = (massmaskRef.current as Massmask);
        if (massmask != null) {
            if (productConfigurationRunId) {
                massmask.setProductConfigurationRunId(productConfigurationRunId);
            }
            if (productConfigurationId) {
                massmask.setProductConfigurationId(productConfigurationId);
            }
        }

    }, [productConfigurationId, productConfigurationRunId])

    useEffect(() => {
        if (massmaskRef.current != null) {
            const massmask = (massmaskRef.current as Massmask);
            massmask.useUnpublished(useUnpublished);
            if (updateShowEvaluationResultInMassmask) {
                massmask.showEvaluationResult();
            }
            else {
                massmask.hideEvaluationResult();
            }
        }
    }, [useUnpublished, updateShowEvaluationResultInMassmask, qualityErpId])

    useEffect(() => {
        let massmask = (massmaskRef.current as Massmask);
        const massMaskEvaluatingChangedHandler = (event: CustomEvent) => {
            updateMassMaskEvaluating();
        }

        const massMaskEvaluationFailedChangedHandler = (event: CustomEvent<FailedDataEvent>) => {
            updateMassMaskEvaluationFailed(event.detail);
        }

        const massMaskEvaluatedChangedHandler = (event: CustomEvent<MassMaskEvaluatedEvent>) => {
            updateMassMaskEvaluated(event.detail);
        }
        const massMaskLoadingChangedHandler = () => {
            updateMassMaskLoading();
        }

        const massMaskLoadingFailedChangedHandler = (event: CustomEvent<FailedDataEvent>) => {
            updateMassMaskLoadingFailed(event.detail);
        }

        const massMaskLoadedChangedHandler = (event: CustomEvent<MassMaskLoadedEvent>) => {
            updateMassMaskLoaded(event.detail);
        }

        const getComponentStateHandler = (event: CustomEvent<ComponentDataEvent>) => {
            onMassMaskComponentUpdated(event.detail);
        }

        if (massmask !== null) {
            massmask.addEventListener("massMaskEvaluating", massMaskEvaluatingChangedHandler);
            massmask.addEventListener("massMaskEvaluationFailed", massMaskEvaluationFailedChangedHandler);
            massmask.addEventListener("massMaskEvaluated", massMaskEvaluatedChangedHandler);
            massmask.addEventListener("massMaskLoading", massMaskLoadingChangedHandler);
            massmask.addEventListener("massMaskLoadingFailed", massMaskLoadingFailedChangedHandler);
            massmask.addEventListener("massMaskLoaded", massMaskLoadedChangedHandler);
            massmask.addEventListener("getComponentState", getComponentStateHandler);

        }
        return () => {
            if (massmask !== null) {
                massmask.removeEventListener("massMaskEvaluating", massMaskEvaluatingChangedHandler);
                massmask.removeEventListener("massMaskEvaluationFailed", massMaskEvaluationFailedChangedHandler);
                massmask.removeEventListener("massMaskEvaluated", massMaskEvaluatedChangedHandler);
                massmask.removeEventListener("massMaskLoading", massMaskLoadingChangedHandler);
                massmask.removeEventListener("massMaskLoadingFailed", massMaskLoadingFailedChangedHandler);
                massmask.removeEventListener("massMaskLoaded", massMaskLoadedChangedHandler);
                massmask.removeEventListener("getComponentState", getComponentStateHandler);
                massmask = null;
            }
        }
    }, []);

    const getStringofEnum = (enumValue: SideSelectionEnum) => {
        if (enumValue === SideSelectionEnum.LeftRight) {
            return "all";
        }
        else if (enumValue === SideSelectionEnum.Left) {
            return "left";
        }
        else if (enumValue === SideSelectionEnum.Right) {
            return "right";
        }

        return "undefined";
    }

    const getStringofProcessTypeEnum = (enumValue: ProcessTypeEnum) => {
        if (enumValue === ProcessTypeEnum.Offer) {
            return "quotation";
        }
        else {
            return "order";
        }
    }

    return (
        <div hidden={!showMassMask} style={{ visibility: showMassMask ? 'visible' : 'collapse' }}>
            <x-massmask id={new Date().toUTCString()}
                ref={massmaskRef}
            ></x-massmask>
        </div>
    );

})