import HttpServiceCommonResult from 'shared/networking/http_service_common_result';
import IAdditionHistoryDetails from '../models/additions_history/addition_history_details';
import IAdditionHistoryOverview from '../models/additions_history/addition_history_overview';
import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from './config.service';

class AdditionHistoryServiceAPI extends HttpServiceCommonResult {
    public async getAdditionsHistoryOverview(): Promise<ApiResult<Array<IAdditionHistoryOverview>>> {
        return this.get<Array<IAdditionHistoryOverview>>(`/${appConfig.proxy}/additionhistory`);
    }

    public async getAdditionHistoryDetails(historyId: number): Promise<ApiResult<IAdditionHistoryDetails>> {
        return this.get<IAdditionHistoryDetails>(`/${appConfig.proxy}/additionhistory/${historyId}`);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const AdditionHistoryService = new AdditionHistoryServiceAPI(TimeoutMilliseconds);

export default AdditionHistoryService;
