import { useAuth } from "shared/providers/auth_provider";
import UnauthorizedComponent from "../../authentication/unauthorized/unauthorized.component";

interface GuardProps {
  requiredRole?: string;
  children?: React.ReactElement;
}

const DefaultBffGuard: React.FC<GuardProps> = ({ requiredRole, children }) => {
  const { authData } = useAuth();
  const accountRoles = authData.roles;

  const isNoRoleRequried = () => requiredRole === "" || requiredRole == null;
  const isNoRoleAvailable = () =>
  accountRoles == null || accountRoles.length === 0;
  const isNoMatchingRole = () => !accountRoles.includes(requiredRole);

  if (!authData.isAuthenticated) {
    return <UnauthorizedComponent />;
  }

  if (isNoRoleRequried() && authData.isAuthenticated) {
     return children;
  }

  if (isNoRoleAvailable()) {
     return <UnauthorizedComponent />;
  }

  if (isNoMatchingRole()) {
    return <UnauthorizedComponent />;
  }

  return children;
};

export default DefaultBffGuard;
